import React from 'react';
import {Input} from "@chakra-ui/react";


type Props = {
  [rest:string]: any;
}


const OikosInput: React.FC<Props> = ({...rest}) => {

  return (
    <Input
      borderColor={"primary-color-1"}
      variant="outline"
      {...rest}
    />
  )
}

export default OikosInput
