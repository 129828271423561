import React from "react";
import {
  Center,
  Flex,
} from "@chakra-ui/react";


type Props = {
  bg?: string
  h?: string | number
  pt?: string | number
  pb?: string | number
  py?: string | number
  children: React.ReactNode
}

const Section: React.FC<Props> = (props) => {

return (
  <Center w={"100%"}>
    <Flex
      bg={props.bg}
      maxW={"100%"}
      width={"100%"}
      justifyContent={"center"}
      h={props.h}
      pt={props.pt}
      pb={props.pb}
      py={props.py}
    >
      {props.children}
    </Flex>
  </Center>
  )

}

export default Section
