import axios from 'axios';

export class Oikos {

    readonly serviceEndPoint: string = process.env.REACT_APP_API_URL || "http://localhost:8090";
    readonly OIKOS_API_KEY: string = process.env.REACT_APP_OIKOS_API_KEY || "<API_KEY_NOT_SET>";
    readonly BANNER_ENABLED: boolean = process.env.REACT_APP_BANNER_ENABLED === "true" || false;


    async Get(url: string, params: any) {
        const {data} = await axios.get(this.serviceEndPoint + url, {
            // headers: this.headers,
            headers: {
                Authorization: `Bearer ${this.OIKOS_API_KEY}`,
            },
            params: params,
        });
        return data;
    }

    async Post(url: string, postData: any) {
        const config = {
            headers: {
                Authorization: `Bearer ${this.OIKOS_API_KEY}`,
            },
        }
        const {data} = await axios.post(this.serviceEndPoint + url, postData, config);
        return data
    }


    signUp(email: string, name: string, familyName: string, userType: string) {
        return this.Post("/users/signup", {
            Email: email,
            Name:  name,
            FamilyName: familyName,
            UserType: userType,
        }).then((data) => {
            return data
        })
    }

    getUser(UserID: string) {
        return this.Post("/users/get", {
            UserID: UserID
        }).then((data) => {
            console.log(data.Payload)
            let user = data.Payload as OIKOS.user
            //Sanitation:
            if (!user.Profile.Languages) {
                user.Profile.Languages = []
            }
            if (!user.Profile.Education) {
                user.Profile.Education = []
            }
            if (user.Profile.Experience) {
                user.Profile.Experience = []
            }
            if (!user.EducatorProfile.Categories) {
                user.EducatorProfile.Categories = []
            }
            if (!user.EducatorProfile.Disciplines) {
                user.EducatorProfile.Disciplines = []
            }
            if (!user.LearnerProfile.NeedsCategories) {
                user.LearnerProfile.NeedsCategories = []
            }
            return user
        });
    }

    updateUser(updatedUser: OIKOS.user) {
        let user = updatedUser
        if ("Picture" in user) {
            delete user["Picture"]
        }

        const postData = {
            UpdatedUser: updatedUser
        }
        console.log(postData)
        return this.Post("/users/update", postData)
          .then((data) => {
            console.log(data)
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }



    changePassword(AccessToken: string, CurrentPassword: string, NewPassword: string) {
        return this.Post("/users/password/change", {
            AccessToken: AccessToken,
            CurrentPassword: CurrentPassword,
            NewPassword: NewPassword,
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    changeContactInfo(UserID: string, Email: string) {
        return this.Post("/users/contact/change", {
            UserID: UserID,
            Email: Email,
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }


    //upload Profile picture does not follow pattern of other methods
    //because it uses multipart/form-data
    async uploadProfilePicture(UserID: string, file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("UserID", UserID);

        const config = {
            headers: {
                Authorization: `Bearer ${this.OIKOS_API_KEY}`,
                'Content-Type': 'multipart/form-data',
            },
        };

        const { data } = await axios.post(this.serviceEndPoint + "/users/upload/profileImage", formData, config);
        return new Promise((resolve, reject) => {
            if (data.Error) {
                reject(new Error(data.Payload));
            } else {
                resolve(data.Payload);
            }
        });
    }
    listEducators() {
        return this.Post("/educators/list", {}).then((data) => {
            return data.Payload
        });
    }

    searchEducators(searchTerms: string[], filters: string[]) {
        console.log(searchTerms)
        return this.Post("/educators/search", {
            SearchTerms: searchTerms,
            Filters: filters,
        }).then((data) => {
            if (data.Payload === null) {
                return []
            }
            return data.Payload
        });
    }

    getEducator(EducatorID: string) {
        return this.Post("/educators/get", {
            EducatorID: EducatorID
        }).then((data) => {
            let educator = data.Payload
            educator.DisplayName = educator.Name + " " + educator.FamilyName.slice(0, 1) + "."
            if (educator.Profile.Languages == null) {
                educator.Profile.Languages = []
            }
            if (educator.Profile.Education == null) {
                educator.Profile.Education = []
            }

            return educator
        });
    }


    sessionRequest(LearnerID: string, EducatorID: string, StartTime: string, SessionDuration: number) {
        return this.Post("/sessions/request", {
            EducatorID: EducatorID,
            LearnerID: LearnerID,
            Duration: SessionDuration,
            StartTime: StartTime
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    sessionAccept(SessionID: string) {
        console.log(SessionID)
        return this.Post("/sessions/accept", {
            SessionID: SessionID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    sessionDecline(SessionID: string) {
        return this.Post("/sessions/decline", {
            SessionID: SessionID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    sessionCancelByEducator(SessionID: string) {
        return this.Post("/sessions/cancel/educator", {
            SessionID: SessionID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    sessionCancelByLearner(SessionID: string) {
        return this.Post("/sessions/cancel/learner", {
            SessionID: SessionID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    getUserConversations(UserID: string): Promise<OIKOS.Conversation[]> {
        return this.Post("/conversations/user/get", {
            UserID: UserID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }

    getConversationMessages(UniqueName: string): Promise<OIKOS.Message[]> {
        return this.Post("/conversations/get", {
            UniqueName: UniqueName
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }


    sendMessage(SenderID: string, RecipientID: string, Message: string): Promise<any> {
        return this.Post("/conversations/messages/send", {
            SenderID: SenderID,
            RecipientID: RecipientID,
            Message: Message
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
    }

    getUserSessions(UserID: string): Promise<OIKOS.Session[]> {
        return this.Post("/users/get/sessions", {
            UserID: UserID
        }).then((data) => {
            return new Promise((resolve, reject) => {
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        });
    }


    addAvailabilityBlock(UserID: string, DayOfTheWeek: number, StartTime: string, EndTime: string): Promise<any> {
        return this.Post("/educators/availability/add", {
            EducatorID: UserID,
            StartTime: StartTime,
            EndTime: EndTime,
            DayOfTheWeek: DayOfTheWeek
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
    }


    getEducatorAvailability(EducatorID: string, StartDate: string, TimeZone: string): Promise<any> {
        return this.Post("/educators/availability/get", {
            EducatorID: EducatorID,
            StartDate: StartDate,
            TimeZone: TimeZone
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
    }

    updateEducatorSchedule(EducatorID: string, Schedule: OIKOS.ScheduleSlot[]): Promise<any> {
        return this.Post("/educators/availability/update", {
            EducatorID: EducatorID,
            Schedule: Schedule
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
          .catch(
            (err) => {
                console.log(err)
            }
          )
    }

    addCreditCard(UserID: string, Name: string, Number: string, ExpiryMonth: number, ExpiryYear: number, CVC: string): Promise<any> {

        const CreditCard = {
            Name: Name,
            Number: Number,
            ExpMonth: ExpiryMonth,
            ExpYear: ExpiryYear,
            CVC: CVC
        }

        console.log(CreditCard)
        return this.Post("/users/paymentmethods/add", {
            UserID: UserID,
            CreditCard: CreditCard
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
    }


    getPaymentMethods(UserID: string): Promise<any> {
        return this.Post("/users/paymentmethods/list", {
            UserID: UserID,
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload))
                } else {
                    resolve(data.Payload)
                }
            })
        })
    }


    async getGooleAuthURL() {
        const data = await this.Get("/integrations/auth/google/url", {})
        console.log(data.Payload)
        window.location.href = data.Payload
    }

    setGoogleToken(userID: string, code: string): Promise<any> {
        return this.Post("/integrations/auth/google/token", {
            UserID:  userID,
            Code: code,
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload));
                } else {
                    resolve(data.Payload);
                }
            });
        });
    }


    getGoogleUserInfo(userID: string): Promise<any> {
        return this.Post("/integrations/auth/google/userinfo", {
            UserID: userID,
        }).then((data) => {
            return new Promise((resolve, reject) => {
                console.log(data)
                if (data.Error) {
                    reject(new Error(data.Payload));
                } else {
                    resolve(data.Payload);
                }
            });
        }).catch((err) => {
            console.log(err)
        })
    }

}
